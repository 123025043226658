import React from 'react'
import color from '../../config/colors'
import { Scrollbars } from 'react-custom-scrollbars';

const CarbonTable = props => {
    const { theme, data, } = props
   
    return (
        <Scrollbars style={{ width: '100%'}}>
          <table className="table border-none" 
            style={{
              borderSpacing: "0 5px",
              borderCollapse: "unset",
              width: '100%'
            }}>
            <thead>
              {/* <tr style={{ fontSize: '16px' ,paddingTop: -10, paddingBottom: 10}}>
                <th className="" style={{ color: color[theme].textColor,position: 'sticky',top:0 }}>
                  <div>Vendor</div>
                </th>
                <th className="" style={{ color: color[theme].textColor,position: 'sticky',top: 0 }}>
                  <div>Model</div>
                </th>
                <th className="" style={{ color: color[theme].textColor, position: 'sticky', top: 0 }}>
                  <div>Performance</div>
                </th>
              </tr> */}
            </thead>
            <tbody>    
                {
                   (data !== null && data.length !== 0) ? data.map((v, k) => {
                        return <Row key={k} theme={theme} data={v} />; 
                    }):
                    <tr style={{
                        // background: '#202B60',
                        borderRadius: '8px'
                    }}>
                        <td colSpan={3} style={{ textAlign: 'center'}}>
                            No Data
                        </td>
                    </tr>
                }          
            </tbody>
          </table>
        </Scrollbars>
      );
};

const Row = ({ theme, data }) => {
    return (
        <tr
            className=""
            title={`${data.id}`}
            style={{
                background: /*'#202B60'*/color[theme].rowBackground,
                fontSize: '12px',
            }}
        >
            <td className="py-3 px-2" style={{borderColor:'transparent' , color: color[theme].bodyText, borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px', paddingLeft: '2px' }}>
                {data.vendor}
            </td>
            <td className="py-3 px-2" style={{borderColor:'transparent' , color: color[theme].bodyText ,paddingLeft: '2px' }}>
                {data.model}
            </td>
            <td className="py-3 px-2" style={{borderColor:'transparent' , color: color[theme].bodyText, borderBottomRightRadius: '5px', borderTopRightRadius: '5px',paddingLeft: '2px' }}>
                {data.performance}%
            </td>
        </tr>
    );
};

export default CarbonTable
