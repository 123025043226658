import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import color from '../../config/colors';
import MapContainer from '../common/map';
import CarbonTable from './carbonTable';

const table_data = [
    { id: 1, vendor: "Trane", model: "China Series S", performance: 1.90 },
    { id: 2, vendor: "Daikin", model: "Series 350", performance: 1.90 },
    { id: 3, vendor: "York", model: "YVWH225", performance: 1.90 },
    { id: 4, vendor: "Carrier", model: "23XRV373", performance: 1.90 },
    { id: 5, vendor: "Daikin", model: "HEX", performance: 1.90 },
    { id: 6, vendor: "Trane", model: "China Series S", performance: 1.90 },
    { id: 7, vendor: "Trane", model: "China Series S", performance: 1.90 }
]
class RightPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showExpandedMap: false
        }
    }

    render() {
        const { theme } = this.props
        return (
            <div className="d-flex flex-column">
                <div className="row p-0 m-0 h-100" style={{  }}>
                    <div className="col-12 col-lg-6 p-1 m-0" >
                        <div className="d-flex flex-column h-100" style={{ background: color[theme].boxBackground, borderRadius: '8px', boxShadow: '0px 1px 3px 1px #00000099' }}>
                            <div className="row p-0 m-0" style={{ fontSize: '14px', background: color[theme].boxBackground, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                                <div className="col-12 p-2">
                                    <span style={{ color: color[theme].boxTitle, fontSize: '16px', fontWeight: 'bold' }}>Green Economy Summary</span>
                                </div>
                                <div className="col-4 p-2 m-0" style={{ borderRight: `1px solid ${color[theme].graph}` }}>
                                    <div style={{ color: color[theme].blue, fontSize: "1em" }}>Total Carbon Avoidance</div>
                                    <div style={{ color: color[theme].textColor, paddingTop: 8, fontSize: '1.2em' }}>468261</div>
                                    <div style={{ color: color[theme].buttonColor, fontWeight: 'bold', fontSize: '1.2em' }}>tCO2-e</div>
                                </div>
                                <div className="col-4 p-2 m-0" style={{ borderRight: `1px solid ${color[theme].graph}` }}>
                                    <div style={{ color: color[theme].blue, fontSize: "1em" }}>Green Jobs Creation</div>
                                    <div style={{ color: color[theme].textColor, paddingTop: 8, fontSize: '1.2em' }}>246</div>
                                    <div style={{ color: color[theme].buttonColor, fontWeight: 'bold', fontSize: '1.2em' }}>SGD</div>
                                </div>
                                <div className="col-4 p-2 m-0">
                                    <div style={{ color: color[theme].blue, fontSize: "1em" }}>Economic<br/>Value</div>
                                    <div style={{ color: color[theme].textColor, paddingTop: 8, fontSize: '1.2em' }} >123456789</div>
                                    <div style={{ color: color[theme].buttonColor, fontWeight: 'bold', fontSize: '1.2em' }}>SGD</div>
                                </div>
                            </div>
                            <div className="" style={{ borderBottom: `1px solid ${color[theme].graph}` }} />
                            <div className="flex-grow-1 d-flex flex-column py-2" style={{ background: color[theme].boxBackground, paddingLeft: 2, paddingRight: 2, borderRadius: '8px' }}>
                                <div className="d-flex p-2 justify-content-between" style={{}}>
                                    <div className="text-light font-weight-bold">World Wide</div>
                                    <div className="kumo-expand" style={{ cursor: "pointer" }} onClick={() => this.setState({ showExpandedMap: true }) } >
                                        <i className="fas fa-expand text-light"></i>
                                    </div>
                                </div>
                                <div className="flex-grow-1" id="map-container" style={{ minHeight: 300 }}>
                                    <MapContainer parentContainer={"map-container"} />
                                </div>
                                <div className="d-flex py-2" style={{}}>
                                    <div className="flex-fill px-1">
                                        <div className="d-flex px-1 py-1" style={{ color: color[theme].boxTitle }}><small>Selected Site</small></div>
                                        <div className="d-flex font-weight-bold">
                                            <div className="px-1"><span style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: 'green', display: 'inline-block' }} /></div>
                                            <div className="flex-grow-1" style={{ fontSize: 13, color: color[theme].textColor }}>Pan Pecific Serviced Suites Orchard</div>
                                        </div>
                                    </div>
                                    <div className="flex-fill px-1" style={{ minWidth: 100 }}>
                                        <div className="d-flex px-2 py-1 justify-content-center" style={{ color: color[theme].boxTitle }}><small>Saved</small></div>
                                        <div className="d-flex justify-content-center font-weight-bold">
                                            <div className="" style={{ fontSize: 15, color: color[theme].textColor }}>$230,000</div>
                                        </div>
                                    </div>
                                    <div className="flex-fill px-1" style={{ minWidth: 100 }}>
                                        <div className="d-flex px-2 py-1 justify-content-center" style={{ color: color[theme].boxTitle }}><small>Green Jobs</small></div>
                                        <div className="d-flex justify-content-center font-weight-bold">
                                            <div className="" style={{ fontSize: 15, color: color[theme].textColor }}>2</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-1 m-0" >
                        <div className="d-flex flex-column h-100" style={{ height: 615, background: color[theme].boxBackground, borderRadius: '8px', boxShadow: '0px 1px 3px 1px #00000099' }}>
                            <div className="row p-0 m-0" style={{ fontSize: '14px' }}>
                                <div className="col-12 p-2">
                                    <span style={{ color: color[theme].boxTitle, fontSize: '16px', fontWeight: 'bold' }}>Carbon Avoidance Potential Investment</span>
                                </div>
                                <div className="col-4 p-2 m-0" style={{ borderRight: `1px solid ${color[theme].graph}` }}>
                                    <div style={{ color: color[theme].blue, fontSize: '1em' }}>Total<br/>Investment</div>
                                    <div style={{ color: color[theme].textColor, paddingTop: 8, fontSize: '1.2em' }}>3,764,151.43</div>
                                    <div style={{ color: color[theme].buttonColor, fontWeight: 'bold', fontSize: '1.2em' }}>SGD</div>
                                </div>
                                <div className="col-4 p-2 m-0" style={{ borderRight: `1px solid ${color[theme].graph}` }}>
                                    <div style={{ color: color[theme].blue, fontSize: '1em' }}>Total<br/>Return</div>
                                    <div style={{ color: color[theme].textColor, paddingTop: 8, fontSize: '1.2em' }}>1,661,834.69</div>
                                    <div style={{ color: color[theme].buttonColor, fontWeight: 'bold', fontSize: '1.2em' }}>SGD</div>
                                </div>
                                <div className="col-4 p-2 m-0">
                                    <div style={{ color: color[theme].blue, fontSize: '1em' }}>Average<br/>ROI</div>
                                    <div style={{ color: color[theme].textColor, paddingTop: 8, fontSize: '1.2em' }} >1.8</div>
                                    <div style={{ color: color[theme].buttonColor, fontWeight: 'bold', fontSize: '1.2em' }}>Years</div>
                                </div>
                            </div>
                            <div className="" style={{ borderBottom: `1px solid ${color[theme].graph}` }} />
                            <div className="p-2" style={{ color: color[theme].blue, paddingBottom: 10, fontWeight: "bold" }}>
                                Asset Performance Branchmark
                            </div>
                            <div className="d-flex justify-content-between px-2" style={{ fontSize: 14, fontWeight: "bold" }}>
                                <div className="" style={{ color: color[theme].textColor, position: 'sticky', top: 0 }}>Vendor</div>
                                <div className="" style={{ color: color[theme].textColor, position: 'sticky', top: 0, paddingRight: 40 }}>Model</div>
                                <div className="" style={{ color: color[theme].textColor, position: 'sticky', top: 0, paddingRight: 20 }}>Performance</div>
                            </div>
                            <div className="table-responsive px-2 pb-2 flex-grow-1" style={{ borderRadius: 8 }}>
                                <CarbonTable theme={theme} data={table_data} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showExpandedMap && <ExpandedMapView theme={theme} onClose={() => this.setState({ showExpandedMap: false })} />}   
            </div>
        )
    }
}

const ExpandedMapView = props => {
    const { theme, onClose } = props
    return (
        <div className="kumo-expanded-map-backdrop">
            <div className="kumo-expanded-map mx-auto my-auto" style={{ background: "#1c275c" }}>
                <div className="d-flex justify-content-end" style={{ position: "absolute", right: 10, top: 10 }}>
                    <div onClick={e => onClose()} className="kumo-expand rounded-circle d-flex justify-content-center align-items-center red-hover" style={{ height: 24, width: 24, cursor: "pointer" }}>
                        <i className="fas fa-times"></i>
                    </div>
                </div>
                <div className="h-100 w-100 d-flex flex-row flex-wrap py-2" style={{ background: "#1c275c" }}>
                    
                    <div className="flex-grow-1 d-flex flex-column p-2 justify-content-between pr-2" style={{}}>
                        <div className="d-flex justify-content-between align-items-center pb-3 pt-2">
                            <div className="text-light font-weight-bold h5 px-2">World Wide</div>
                        </div>
                        <div className="flex-grow-1 flex-fill d-flex map-container">
                            <div className="flex-fill" id="map-container-expand" style={{ minHeight: 300 }}>
                                <MapContainer parentContainer={"map-container-expand"} />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column py-2 pl-2 align-self-stretch" style={{}}>
                        <div className="d-flex justify-content-between align-items-center pb-3 pt-2">
                            <div className="text-light font-weight-bold h5 px-2">Site Information</div>
                        </div>
                        <div className="d-flex py-2 site-info-container flex-grow-1" style={{}}>
                            <div className="flex-fill px-1">
                                <div className="d-flex px-1 py-1" style={{ color: color[theme].boxTitle }}><small>Selected Site</small></div>
                                <div className="d-flex font-weight-bold">
                                    <div className="px-1"><span style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: 'green', display: 'inline-block' }} /></div>
                                    <div className="flex-grow-1" style={{ fontSize: 13, color: color[theme].textColor }}>Pan Pecific Serviced Suites Orchard</div>
                                </div>
                            </div>
                            <div className="flex-fill px-1" style={{ minWidth: 100 }}>
                                <div className="d-flex px-2 py-1 justify-content-center" style={{ color: color[theme].boxTitle }}><small>Saved</small></div>
                                <div className="d-flex justify-content-center font-weight-bold">
                                    <div className="" style={{ fontSize: 15, color: color[theme].textColor }}>$230,000</div>
                                </div>
                            </div>
                            <div className="flex-fill px-1" style={{ minWidth: 100 }}>
                                <div className="d-flex px-2 py-1 justify-content-center" style={{ color: color[theme].boxTitle }}><small>Green Jobs</small></div>
                                <div className="d-flex justify-content-center font-weight-bold">
                                    <div className="" style={{ fontSize: 15, color: color[theme].textColor }}>2</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RightPanel;