import React,{ Component } from 'react';
import { Route, Switch, Redirect,withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import routes,{routeName} from './routes';
import './App.css';

class App extends Component {
  constructor(props){
    super(props)
    this.state={
      theme: 'dark'
    }
  }

  render(){
    const { theme } = this.state;
    return(
      <div className="d-flex flex-column p-0 m-0" style={{
          height: '100%', position: 'absolute', left: '0px', width: '100%', overflow: 'auto',
          background: theme === "dark" ? "linear-gradient(-60deg, #162955,#202B60)" : theme === "light" ? "linear-gradient(-60deg,#e6e8eA,#F6F8FA)" : null
        }}>
         <Switch>
         <Route exact path="/">
         <Redirect to={routeName.routeLogin}/>
        </Route>
          {Object.keys(routes.routes).map((name, k) =><Route key={k} path={`/${name}`} component={routes.routes[name].component} />)}
        </Switch>
      </div>
    )
  }
}


export default App;
