import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%'
  // bottom: 0
};

const containerStyle = {
  position: 'relative',  
  width: '100%',
  height: '100%',
}

const markerLocs = [
    {
      name: "Pan Pecific Serviced Suites Orchard 1",
      pos: { 
        lat: 4.2096721,
        lng: 100.5770485 
      }
    },
    {
      name: "Pan Pecific Serviced Suites Orchard 2",
      pos: { 
        lat: 4.4096721,
        lng: 100.0770485 
      }
    },
    {
      name: "Pan Pecific Serviced Suites Orchard 3",
      pos: { 
        lat: 5.4096721,
        lng: 101.0770485 
      }
    },
    {
      name: "Pan Pecific Serviced Suites Orchard 4",
      pos: { 
        lat: 5.9096721,
        lng: 102.2770485 
      }
    },
    {
      name: "Pan Pecific Serviced Suites Orchard 5",
      pos: { 
        lat: 6.9096721,
        lng: 100.9770485 
      }
    }
]

export class MapContainer extends Component {
  constructor() {
    super()
    this.state = {
      parentHeight: 0,
      parentWidth: 0
    }
    this.mapRef = React.createRef(null)
  }

  componentDidMount() {
    const parent = document.getElementById(this.props.parentContainer)
      if(parent) {
        this.setState({ parentHeight: parent.clientHeight })
        this.setState({ parentWidth: parent.clientWidth })
      }
    window.addEventListener("resize", e => {
      const parent = document.getElementById(this.props.parentContainer)
      if(parent) {
        this.setState({ parentHeight: parent.clientHeight })
        this.setState({ parentWidth: parent.clientWidth })
      }
    })

  }

  onMapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: googleMapStyles
    })
   
    const mapRef = this.mapRef.current
    // console.log(mapRef)
    this.addMarkers(mapRef.map)

  }

  addMarkers = (map) => {

    for(let mloc of markerLocs) {
      const randomNo = Math.floor(Math.random() * 10) + 1 
      // console.log("/assets/markers/marker-icon-"+randomNo+".png")
      const marker = new this.props.google.maps.Marker({
        // draggable: true,
        animation: this.props.google.maps.Animation.DROP,
        position: mloc.pos,
        title:"Pan Pecific Serviced Suites Orchard",
        icon: "/assets/markers/marker-icon-"+randomNo+".png"
      });
      marker.setMap(map)
    }

   
  }


  render() {
    return (
      // <div style={{ position: "relative", height: "100%" }} className="border border-success py-4">
        <Map
          google={this.props.google}
          zoom={5}
          style={{ width: this.state.parentWidth, height: this.state.parentHeight }}
          initialCenter={
            {
              lat: 4.2096721,
              lng: 100.5770485
            }
          }
          ref={this.mapRef}
          onReady={this.onMapLoaded}
          zoomControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
          scaleControl={false}
          minZoom={1}
        />
      // </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB5fin4pF25QcrXcpnO9Uiip3K_WTiplb0'
})(MapContainer);

const googleMapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#1c275c",
      }
    ]
  },
  {
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#1c275c"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8ec3b9"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1a3646"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b6878"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#64779e"
      }
    ]
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b6878"
      }
    ]
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#334e87"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4ea1d9"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#283d6a"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6f9ba5"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#4ea1d9"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#4ea1d9"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#9ed1f9"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ddf5ee"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#d0f0e5"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#023e58"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#98a5be"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1d2c4d"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#283d6a"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3a4762"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#1c275c",
        visibility: "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#4e6d70"
      }
    ]
  }
]