import React,{ Component , Fragment} from 'react';
import color from '../../config/colors';
import Linechart from '../common/lineChart';

const test_data = [
    {date: 'Jan', value: 12},
    {date: 'Feb', value: 45},
    {date: 'Mar', value: 23},
    {date: 'Apr', value: 50},
    {date: 'May', value: 44},
    {date: 'Jun', value: 33},
    {date: 'July', value: 13},
    {date: 'Aug', value: 32},
    {date: 'Sep', value: 23},
    {date: 'Oct', value: 12},
    {date: 'Nov', value: 34},
    {date: 'Dec', value: 32},
];

class BottomtPanel extends Component {
    constructor(props){
        super(props)
        this.state={
          theme: 'dark',
          active: 'CB'
        }
      }

    changeGraph = (active) =>{
        this.setState({ active })
    }

    render(){
        const { theme , active } = this.state
        return(
            <div className="d-flex flex-column p-1">
                <div className="py-2" style={{ background: color[theme].boxBackground, borderRadius: '8px',boxShadow: '0px 1px 3px 1px #00000099'}}>
                    <div className="p-2 px-3" style={{ fontSize: '16px', fontWeight: "bold", color: color[theme].boxTitle}}>
                        Key Performance Indicators
                    </div>
                    <div className="row p-0 m-0">
                        <div className="col-12 col-lg-3 py-2" style={{ fontSize: '14px'}}>
                            <div className="p-2" style={{ borderLeft: active === 'CB' ? `2px solid ${color[theme].blue}` : 'none', cursor: 'pointer'}} onClick={(e) => this.changeGraph("CB")}>
                                <span style={{ color: color[theme].boxTitle}}>Carbon Avoidance</span> 
                                <span style={{ paddingLeft: '20px',fontSize: '16px', color: 'white'}}>$2,354</span> 
                                <span style={{ paddingLeft: '15px', color: 'green'}}>+12.00%</span>
                            </div>
                            <div className="p-2" style={{ borderLeft: active === 'ES' ? `2px solid ${color[theme].blue}` : 'none', cursor: 'pointer'}} onClick={(e) => this.changeGraph("ES")}>
                                <span style={{ color: color[theme].boxTitle}}>Energy Saving</span> 
                                <span style={{ paddingLeft: '45px',fontSize: '16px', color: 'white'}}>$1,354</span> 
                                <span style={{ paddingLeft: '15px', color: 'green'}}>+1.00%</span>
                            </div>
                            <div className="p-2" style={{ borderLeft: active === 'PF' ? `2px solid ${color[theme].blue}` : 'none', cursor: 'pointer'}} onClick={(e) => this.changeGraph("PF")}>
                                <span style={{ color: color[theme].boxTitle}}>Profit</span> 
                                <span style={{ paddingLeft: '100px',fontSize: '16px', color: 'white'}}>$354</span> 
                                <span style={{ paddingLeft: '20px', color: 'green'}}>+2.00%</span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 p-0 m-0" style={{ minHeight: 200 }} >
                            <Linechart 
                                data = {test_data}
                                theme= {theme}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BottomtPanel;