import React,{ Component , Fragment} from 'react';
import color from '../../config/colors';
import AChart from '../common/areaChart';
import Table from './table';
import { Scrollbars } from 'react-custom-scrollbars';
import '../../App.css';

const windowWidth = window.innerWidth

const test_data = [
    {date: '00:00', value: 12},
    {date: '01:00', value: 45},
    {date: '02:00', value: 23},
    {date: '03:00', value: 50},
    {date: '04:00', value: 44},
    {date: '05:00', value: 33},
    {date: '06:00', value: 13},
    {date: '07:00', value: 32},
    {date: '08:00', value: 23},
    {date: '09:00', value: 12},
    {date: '10:00', value: 34},
    {date: '11:00', value: 32},
    {date: '12:00', value: 43}
];

const table_data = [
    {siteName: 'Pan Pecific Serviced Suites Orchard', countyName: 'Singapore', date: '30.01.2019', price1 : 7500, price2: 7890, target1 : 4493000, target2: 4500000},
    {siteName: 'Pan Pecific Serviced Beach Road', countyName: 'Singapore', date: '01.10.2019', price1 : 6500, price2: 6890, target1 : 3285000, target2: 3560000},
    {siteName: 'PARKROYAL COLLECTION Pickering', countyName: 'Singapore', date: '12.12.2019', price1 : 5500, price2: 5890, target1 : 1689000, target2: 2050000},
    {siteName: 'PARKROYAL on Kitchener Road', countyName: 'Singapore', date: '30.01.2020', price1 : 4500, price2: 4890, target1 : 4493000, target2: 4500000},
    {siteName: 'PARKROYAL on Beach Road', countyName: 'Singapore', date: '10.10.2020', price1 : 3500, price2: 3890, target1 : 4493000, target2: 4500000},
    {siteName: 'President Bakery', countyName: 'Thailand', date: '11.11.2020', price1 : 2500, price2: 2890, target1 : 4493000, target2: 4500000},
]

class LeftPanel extends Component {
    constructor(props){
        super(props)
        this.state={
            active: "all",
        }
      }

    tableChanges = (active) =>{
        this.setState({ active })
    }

    render(){
        const { theme} = this.props;
        const { active } = this.state
        return(
            <div className="d-flex flex-column h-100">
                <div className="row p-0 m-0">
                    <div className="col-md-6 p-1">
                        <div className="py-2 h-100 d-flex flex-column" style={{background: color[theme].boxBackground, borderRadius: '8px', boxShadow: '0px 1px 3px 1px #00000099', marginBottom: windowWidth < 950 ? 10 : 0}}>
                            <div className="px-3 flex-grow-1">
                                <span style={{ color: color[theme].boxTitle,fontSize: '14px', fontWeight: 'bold'}}>Total Energy Efficiency Opportunity Identified</span>
                            </div>
                            <div className="d-flex pt-2">
                                <div className="d-flex flex-column px-3">
                                    <div className="d-flex flex-column">
                                        <div style={{ color: color[theme].bodyText, fontSize: 20}}>83,000,000</div>
                                        <div style={{ color: 'grey', fontSize: 16, fontWeight: "bold"}}>kWh</div>
                                    </div>
                                    <div className="d-flex align-items-center"> 
                                        <div style={{ color: color[theme].blue, fontSize: 18}}>17,786,900</div>
                                        <div style={{ color: 'grey', fontSize: 16, fontWeight: "bold", paddingLeft: 8}}>$</div>
                                    </div>
                                </div>
                                <div className="flex-grow-1" style={{overflow: 'hidden' }}>
                                    <AChart 
                                        data={test_data}
                                        theme={theme}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-1">
                        <div className="py-2 h-100 d-flex flex-column" style={{background: color[theme].boxBackground, borderRadius: '8px',boxShadow: '0px 1px 3px 1px #00000099'}}>
                            <div className="px-3 flex-grow-1">
                                <span style={{ color: color[theme].boxTitle,fontSize: '14px', fontWeight: 'bold'}}>kWh Saved (achieved)</span>
                            </div>
                            <div className="d-flex pt-2">
                                <div className="d-flex flex-column px-3">
                                    <div className="d-flex flex-column">
                                        <div style={{ color: color[theme].bodyText, fontSize: 20}}>20,000,000</div>
                                        <div style={{ color: 'grey', fontSize: 16, fontWeight: "bold"}}>kWh</div>
                                    </div>
                                    <div className="d-flex align-items-center"> 
                                        <div style={{ color: color[theme].blue, fontSize: 18}}>4,286,000</div>
                                        <div style={{ color: 'grey', fontSize: 16, fontWeight: "bold", paddingLeft: 8}}>$</div>
                                    </div>
                                </div>
                                <div className="flex-grow-1 " style={{overflow: 'hidden' }}>
                                    <AChart 
                                        data={test_data}
                                        theme={theme}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 flex-grow-1 pt-2 pb-1 px-1">
                    <div className="py-2 pl-1 pr-1 d-flex flex-column w-100" style={{ background: color[theme].boxBackground, borderRadius: '8px',boxShadow: '0px 1px 3px 1px #00000099'}}>
                        <div className="py-2 px-2 w-100">
                            <Scrollbars style={{ width: "100%", minHeight: 32 }} autoHeight={true}>
                                <div style={{ display: "inline", whiteSpace: "nowrap" }}>
                                    <div onClick={(e) => this.tableChanges("all")} className="btn btn-sm btn-secondary m-1" style={{ border: 'none', fontSize: 13, backgroundColor: active === "all" ? color[theme].blue : color[theme].buttonColor}}>All Projects (123)</div>
                                    <div onClick={(e) => this.tableChanges("efficiency")} className="btn btn-sm btn-secondary m-1" style={{ border: 'none', fontSize: 13, backgroundColor: active === "efficiency" ? color[theme].blue :color[theme].buttonColor}}>Efficiency Projects</div>
                                    <div onClick={(e) => this.tableChanges("carbon")} className="btn btn-sm btn-secondary m-1" style={{ border: 'none', fontSize: 13, backgroundColor: active === "carbon" ? color[theme].blue :color[theme].buttonColor}}>Carbon Offset Projects</div>
                                </div>
                            </Scrollbars>
                        </div>
                        <div className="table-responsive py-1 flex-grow-1" style={{ minHeight: 300 }}>
                            <Table 
                                data={table_data}
                                theme={theme}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftPanel;