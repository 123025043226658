import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { writeCookie, readCookieLastLoggedUser } from '../../helper/cookieUser'
import { moveToDashboard } from '../../helper/pathRouter'
import { fetchLoginControllerLogin } from "../../network/apiFetcher"
import '../../App.css';

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            errMsg: '',
            isRevealPwd: false
        }
    }

    onChange = data => this.setState(data)

    _handleLogin = e => {
        e.preventDefault()
        const { email, password } = this.state
        fetchLoginControllerLogin({ email, password }, (networkErr, userError, data1) => {
            if (networkErr !== null) this.setState({ errMsg: networkErr.message })
            else if (userError !== null) { this.setState({ errMsg: JSON.stringify(userError.error).replace(/(^")|("$)/g, '') }); }
            else {
                const data = { ...data1.payload }
                // Login success process here
                writeCookie(this.props.cookies, data) // save user current logged
                // move to dashboard
                moveToDashboard(this.props.history)
            }
        })
    }

    render() {
        const { errMsg } = this.state
        return (
            <div className="mx-auto my-auto p-4 bg-white shadow-lg rounded w-25" style={{ color: 'rgb(22, 41, 85)', minWidth: '300px' }}>
                <div className="row justify-content-center align-items-center">
                    <img src={'/logo/logo.dark.png'} alt="logo" height={"35px"} width={"180px"} />
                </div>
                <div>
                    <form className="pt-3" onSubmit={this._handleLogin}>
                        {errMsg &&
                            <div className="row justify-content-center align-items-center pb-1"
                                style={{ fontSize: "20px", color: "red", fontWeight: 600 }}>{errMsg}</div>
                        }
                        <div className="form-group">
                            <label for="exampleInputEmail1" >Email</label>
                            <input
                                type="email" required
                                placeholder="Email"
                                className="form-control"
                                id="exampleInputEmail1"
                                value={this.state.email}
                                onChange={(e) => this.onChange({ email: e.target.value })}
                                style={{ padding: '25px', outline: 'none', boxShadow: 'none', }} />
                        </div>
                        <div className="form-group">
                            <label for="exampleInputPassword1">Password</label>
                            <input
                                type={this.state.isRevealPwd ? "text" : "password"} required
                                placeholder="Password"
                                className="form-control"
                                id="exampleInputPassword1"
                                value={this.state.password}
                                onChange={(e) => this.onChange({ password: e.target.value })}
                                style={{ padding: '25px', outline: 'none', boxShadow: 'none' }} />
                            <span
                                toggle="#password-field"
                                className={this.state.isRevealPwd ? "fa fa-fw fa-eye fa-eye-slash field-icon toggle-password" : "fa fa-fw fa-eye field-icon toggle-password"}
                                onClick={() => this.setState({ isRevealPwd: !this.state.isRevealPwd })}
                            ></span>
                        </div>

                        <div className="form-group pt-3">
                            <button type="submit" className="btn btn-primary btn-lg btn-block px-3">LOGIN</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default withCookies(Login);