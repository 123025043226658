import {
    LOGIN_URL,
} from "./api.decarbon"


export const fetchLoginControllerLogin = ({ email, password }, callback) => {

    if (email !== null && password !== null) {
        fetch(`${LOGIN_URL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*"
            },
            body: JSON.stringify({ email: email, password: password }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) callback(null, null, data)
                else callback(null, data, null)
            })
            .catch(error => callback(error, null, null))
    } else {
        callback(null, { "status": false, "message": "User or password something went wrong..." }, null)
    }
}