import React,{Component} from 'react';
import color from '../../config/colors'
import { Scrollbars } from 'react-custom-scrollbars';
import { ProgressBar } from 'react-bootstrap';

class Table extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    render(){
        const { data, theme } = this.props
        return(
            <Scrollbars style={{ width: '100%', height: "100%" }}>
                <table className="table" style={{ color: color[theme].textColor, fontSize: "0.9em"}}>
                    <tbody>
                        {
                            data !== null && data.length !== 0 ?
                            data.map((d,i) => 
                            <tr key={i} >
                            <td className="" style={{ width: 10, padding: 0, paddingTop: 2, borderColor: i>0 ? color[theme].graph : "transparent"}}>
                                <div className="pt-2 pl-2" style={{ }}>
                                    <span style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green', display: 'inline-block'}}/>
                                </div>
                            </td>
                            <td className='py-2' style={{ borderColor: i>0 ? color[theme].graph : "transparent"}}>
                                <div style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>{d.siteName}</div>
                                <div style={{ whiteSpace: "nowrap", color: '#838396', fontSize: "0.84em" }}>{d.countyName}, Started {d.date}</div>
                            </td>
                            <td className='py-2 px-2' style={{ maxWidth: 100, borderColor: i>0 ? color[theme].graph : "transparent"}}>
                                <div style={{ whiteSpace: "nowrap" }}>${d.price1}</div>
                                <div style={{ whiteSpace: "nowrap", color: '#838396', fontSize: "0.84em" }}>This Month</div>
                            </td>
                            <td className='py-2' style={{ maxWidth: 100, borderColor: i>0 ? color[theme].graph : "transparent"}}>
                                <div style={{ whiteSpace: "nowrap" }}>${d.price2}</div>
                                <div style={{ whiteSpace: "nowrap", color: '#838396', fontSize: "0.84em" }}>Avg Monthly Saving</div>
                            </td>
                            <td className='py-2 pl-4' style={{ borderColor: i>0 ? color[theme].graph : "transparent"}}>
                                <div className="d-flex px-1 align-items-center flex-nowrap" style={{ whiteSpace: "nowrap" }} >
                                    <div className="" style={{ whiteSpace: "nowrap", color: '#838396', fontSize: "0.84em" }}>Target</div>
                                    <div className="px-1" style={{ whiteSpace: "nowrap" }}>${d.target1}/${d.target2}</div>
                                </div>
                                <div className="px-1 pt-1 d-flex align-items-center">
                                    <div className="progressBar flex-fill">
                                        <ProgressBar 
                                            now={((d.target1/d.target2)*100).toFixed(1)} 
                                            // label={`${((d.target1/d.target2)*100).toFixed(1)}%`} 
                                            striped 
                                            variant={color[theme].blue}
                                            style={{ height: 6, fontSize: 10, borderRadius: 6, width: "100%" }}
                                        />
                                    </div>                                   
                                </div>
                            </td>
                        </tr>
                            ) :
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'center'}}>
                                    No Data
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </Scrollbars>
        )
    }
}

export default Table;