import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
 
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

class CustomInput extends React.Component{
    render(){
        return(
            <div className="">
                <i onClick={this.props.onClick}  className="far fa-calendar-alt" style={{ position: "absolute", top: 9, left: 12 }} />
                <input onClick={this.props.onClick} className="dateInput" value={this.props.value} type="text" style={{
                      height: "35px",
                      width:"150px",
                      borderRadius: "3px",
                      background: "#202B60",
                      color: "#e5e5e5",
                      border: '1px solid #1FA9FF',
                      fontSize:'13px',
                    }}/>
            </div>
        )
    }
}

class App extends Component {

  constructor (props) {
    super(props)
    this.state = {
      startDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
    // this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    })
  }

  
 
  render() {
    return (
    
        <div className={"form-group text-center " + (this.props.className ? this.props.className : "")}>
          <DatePicker
              selected={ this.state.startDate }
              onChange={ this.handleChange }
              name="startDate"
              dateFormat="dd-MMMM-yyyy"
              wrapperClassName="react-datepicker-wrapper-custom"
              customInput={<CustomInput/>}
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: "5px, 10px"
                }
              }}
          />
          
        </div>
    
    );
  }
  
}

export default App;

// import React from 'react';
// import DatePicker from "react-datepicker";
// import color from '../../config/colors'
// import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/css/bootstrap.min.css';


// const CustomInputView = props => {
//   const { theme, value, onClick,className=""} = props
//   return (
//     <div onClick={onClick} className='px-3 py-4' style={{
//       // border: `1px solid ${color[theme].graph}`,
//       // borderRadius: 4,
//       // background: color[theme].background1,
//       color: color[theme].blue
//     }}>
//       { value }
//     </div>    
//   )
// }


// const Datepicker = (props)=> {
//   const { theme="dark" } = props  

//   return (
//     <div className="" style={{ 
//       border: `1px solid ${color[theme].blue}`,
//       borderRadius: 4,  
//       background: color[theme].background}}>   
//       <DatePicker 
//         selected={props.selectedDate}
//         onChange={date => props.onChange(date)}
//         wrapperClassName="react-datepicker-wrapper-custom"
//         className=""
//         customInput={<CustomInputView theme={theme} />}
//       />
//     </div>
//   );
// };


// export default Datepicker;

