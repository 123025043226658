export default {
    dark: {
        background: "#202B60",
        blue: "#1FA9FF",
        bodyText: "#ececec",
        graph: "#AAD0FD22",
        title: "#D4CFCF",
        textColor: '#FFFFFF',
        boxTitle: '#d5d5d5',
        buttonColor: '#8396a6',
        boxBackground: "linear-gradient(55deg, #101540 0%, #202B60 87%)",
        rowBackground: "linear-gradient(45deg, #202B60 0%, #303B70 87%)",
    },
    light: {
        background: "#FeFfFf",
        blue: "#0B7DC6",
        bodyText: "#4A5F7C",
        graph: "#A5B2C4",
        title: "#606060",
        textColor: '#202020',
        boxTitle: '#404040',
        buttonColor: '#535353',
        boxBackground: "linear-gradient(-60deg,#e6e8eA,#F6F8FA)",
        rowBackground: "linear-gradient(45deg, #202B60 0%, #303B70 87%)",
    }
}