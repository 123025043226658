import React from 'react'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer,
} from 'recharts';
import color from '../../config/colors'

const Linechart = (props) => {

    const { data, theme } = props
    
    const data1 = data !== null ? data : [];
    // const intervalValue = data1 !== null ? Math.ceil(data1.length / 8) : null    
    const windowWidth = window.innerWidth
    return (
        <div className="p-0 m-0 w-100 h-100">
            <ResponsiveContainer width='100%' height={"100%"} >
            <LineChart
                    data={data1}
                    margin={{
                        top: 5, right: 30, left: -20, bottom: 10,
                    }}
                >   
                    <CartesianGrid stroke={color[theme].graph} /> { /* vertical={false} */}
                    <XAxis 
                        stroke={color[theme].graph}
                        dataKey="date"
                        // interval={intervalValue}
                        angle={windowWidth < 600 ? -60 : 0}
                        dy={windowWidth < 600 ? 15 : 5}
                        dx={windowWidth < 600 ? -10 : 1}
                        tick={{ fontSize: '14px'}}
                        tickSize={0}
                    />
                    <YAxis
                        stroke={color[theme].graph}
                        tick={{ fontSize: '14px'}}
                        tickSize={0}
                        dx={-5}
                    />
                    {/* <Tooltip content={<CustomTooltip active={false} unit={false} waterQuality={true} />}/> */}
                    {/* <Legend content={<CustomLegend deviceListForLegend={deviceListForLegend}/>}/> */}
                    
                    <Line type="linear" dataKey="value" stroke={color[theme].blue} strokeWidth={2} style={{ cursor: 'pointer'}}/>
                </LineChart>
            </ResponsiveContainer>
        </div>

    )
}

export default Linechart;