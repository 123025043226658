import React, { Component,useState } from 'react';
import color from '../../config/colors'
import LeftPanel from '../../components/dashboard/leftPanel';
import RightPanel from '../../components/dashboard/rightPanel';
import BottomtPanel from '../../components/dashboard/bottomPanel';
import DatePicker from "../../components/common/datePicker";



class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            theme: 'dark',
        };
    }
    

    render(){
        const {theme} = this.state

        return(
            <div className="container-fluid px-0 px-xl-3 pb-lg-3">
                <div className="m-0">
                    <div className="d-flex justify-content-between " style={{marginLeft: 14}}>
                        <img src={'/logo/logo.dark.png'} alt="logo" height={"35px"} width={"180px"}/>
                        <button style={{fontSize: 13,paddingLeft: 10,paddingRight: 10, paddingTop: 5,borderRadius: 3, paddingBottom: 5, backgroundColor: 'transparent', color: color[theme].boxTitle,fontWeight:'400',  border: '1px solid #1FA9FF', marginRight: 14,marginTop:10}}>
                        <span style={{color:'#01ffff' ,paddingRight: 10,paddingLeft: 3 }}><i class="fas fa-file-download"></i></span>    
                        Download Report
                        </button>
                        
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-3 flex-wrap pr-2 pl-1" style={{ color: 'white',  marginLeft: 14}}>
                        <div className="d-flex align-items-center flex-grow-1 flex-wrap">
                            <div style={{ fontSize: '19px', fontWeight: 'normal', paddingRight: 10,  }}>Decarbonization Platform</div> 
                            <div style={{ color: 'gray', fontSize: 12}}>Updated 1 minute ago</div>
                        </div>
                        <div className="d-flex align-items-center pr-1 py-2">                            
                            <DatePicker
                                className="pr-2 m-0"
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                                name="startData"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="please select a date"                                    
                                value={this.props.startDate}
                            /> 
                            <div className="pr-2 d-flex align-items-center font-weight-bold">-</div>
                            <DatePicker
                                className="m-0"
                                selected={this.state.startDate}
                                onChange={this.handleChange}
                                name="startData"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="please select a date"   
                                wrapperClassName="react-datepicker-wrapper-custom"                                  
                                value={this.props.startDate}
                            />                           
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-12 col-xl-6 m-0 p-1 ">
                            <LeftPanel theme={theme}/>
                        </div>
                        <div className="col-lg-12 col-xl-6 m-0 p-1">
                            <RightPanel theme={theme}/>
                        </div>
                    </div>

                    <div className="row p-0">
                        <div className="col-lg-12 p-1">
                            <BottomtPanel />
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}

export default Dashboard;