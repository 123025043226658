import React from 'react'
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer,
} from 'recharts';
import color from '../../config/colors'
import "../../App.css";
import { useMediaQuery } from 'react-responsive'

const AChart = (props) => {

    const { data, theme } = props

    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    
    const data1 = data !== null ? data : [];

    const intervalValue = data1 !== null ? Math.ceil(data1.length / 8) : null  

    return (
        <div className="p-0 m-0">
        <ResponsiveContainer width={/*isBigScreen? 285: 220*/"100%"} height={120} >
                <AreaChart
                    data={data1}
                    margin={{
                        top: 5, right: 5, left: -30 , bottom: 5,
                    }}
                >   
                    <defs>
                        <linearGradient id={"gColor"} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="3%" stopColor={color[theme].blue} stopOpacity={1} />
                            <stop offset="70%" stopColor={color[theme].blue} stopOpacity={0.15} />
                            <stop offset="27%" stopColor={color[theme].blue} stopOpacity={0.10} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid vertical={false} horizontal={false} stroke={color[theme].graph} strokearray="3 3" />
                    <XAxis 
                        stroke={color[theme].buttonColor}
                        dataKey="date"
                        interval={intervalValue}
                        angle={-50}
                        dy={10}
                        dx={-13}
                        tick={{ fontSize: '11px'}}
                        tickSize={1}
                        // label={{ value: "TIME",dy: windowWidth < 600 ? 40 : 20, fill: color[theme].bodyText, fontSize: '12px'}} 
                    />
                    <YAxis
                        stroke={color[theme].buttonColor}
                        tick={{ fontSize: '11px'}}
                        tickSize={1}
                        dx={-3}
                        // label={{ value: unit, angle: -90, position: 'insideLeft', fill: color[theme].bodyText, fontSize: '12px'}}
                    />                   
                    <Area type="monotone" dataKey="value" stroke={color[theme].blue} connectNulls={false} fillOpacity={0.5} fill="url(#gColor)" strokeWidth={1} />
                </AreaChart>
            </ResponsiveContainer>
        </div>

    )
}

export default AChart;