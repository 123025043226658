import Dashboard from "../pages/dashboard";
import Login from "../pages/login"


export const routeName = {
    routeDashboard: "dashboard",
    routeLogin: "login"
}

export default {
    routes:{
        [routeName.routeDashboard]:{
            component: Dashboard,
        },
        [routeName.routeLogin]:{
            component: Login,
        },
    }
}